.upload-popup-wrapper {
  background-image: url("../assets/img/banner.jpeg");
  background-size: cover;
  height: 100vh;

  .upload-popup-cnt {
    .full {
      display: flex !important;
      justify-content: center;
      align-items: center;
      height: 100vh;

      .forgotBtn {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: #ffffff;
        margin-bottom: 10px;
      }

      .resendBtn {
        width: 100%;
        display: flex;
        justify-content: center;
        color: #ffffff;
        margin-bottom: 10px;
      }

      form {
        display: flex;
        flex-direction: column;
        align-items: center;
        width: 100%;

        .form-check-label {
          color: #ffffff !important;
          font-size: 14px;
          font-weight: 300 !important;

          a {
            color: #ffffff;
          }
        }

        .full-part {
          width: 100%;
        }

        .sign {
          color: #ffffff;

        }

        span {
          color: #ffffff;
        }
      }
    }
  }
}


.upload-popup-wrapper.create-account {

  .upload-popup-cnt .input-block input {
    width: 100%;
    box-sizing: border-box;
    height: 54px;
    border-radius: 4px;
    padding: 0 12px;
    margin: 0;
    background-color: rgb(116 116 116 / 25%) !important;
    border: none !important;
    color: #ffffff !important;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }

    &:focus-visible {
      outline: none !important;
      box-shadow: none !important;
      border: none !important;
    }
  }

  .upload-popup-cnt .input-block label {
    font-weight: 400;
    font-size: 14px;
    line-height: 12px;
    color: #ffffff;
    display: block;
    width: 100%;
    margin: 0 0 8px 0;
    padding: 0;
  }

  .upload-popup-cnt p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #ffffff;
    margin: 0 0 30px 0;
    padding: 0;
  }

  .upload-popup-cnt h2 span {
    font-weight: 700;
  }

  .upload-popup-cnt h2 {
    font-weight: 400;
    font-size: 48px;
    line-height: 56px;
    color: #ffffff;
    margin: 0 0 12px 0;
    padding: 0;
  }

  .topBanner {
    display: none;
    position: absolute;
    width: 100%;
    height: 100px;
    top: 0;
    background-image: url("../assets/img/banner.jpg");
    background-size: cover;
  }

  .upload-popup-cnt {
    width: 100%;
    max-height: 100vh;
    overflow: auto;
    box-shadow: 0 30px 60px rgba(0, 0, 0, 0.12);
    padding: 0;

    .iconBtn {
      color: #ffffff !important;
    }
  }

  .full {
    display: grid;
    grid-template-columns: 1fr 540px;
    align-items: center;

    .form-card {
      max-width: 500px;
      width: 100%;
      padding: 60px 20px 36px;

      .logo-icon {
        width: 70px;
      }
    }

    img {
      width: 100%;
      object-fit: cover;
    }
  }

  .two-part {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 30px;
  }

  .full-part {
    display: grid;
    grid-template-columns: 1fr;
  }

  .form {
    position: relative;

    button {
      width: 98px;
      height: 38px;
      background: #ffffff;
      border-radius: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-weight: 400;
      font-size: 14px;
      line-height: 12px;
      color: #141414;
      position: absolute;
      top: 8px;
      right: 8px;
      margin: 0 !important;

      &:hover {
        border: 1px solid #ffffff;
        background: transparent;
        color: #ffffff;
      }
    }
  }

  .input-block button {
    margin-top: 6px;
    margin-bottom: 22px;
  }

  .sign {
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 4px;

    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: rgba(20, 20, 20, 0.72);

    a {
      font-weight: 600;
      font-size: 16px;
      line-height: 16px;
      color: #ffffff;
      opacity: 1;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 18px;
    height: 18px;
    background-size: 18px;
    cursor: pointer;

    svg {
      color: #ffffff;
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 1200px) {
  .upload-popup-wrapper.create-account .upload-popup-cnt {
    width: 95%;
  }

  .upload-popup-wrapper.create-account .full .left {
    padding: 60px 60px 36px;
  }
}

@media screen and (max-width: 1024px) {
  .upload-popup-wrapper.create-account .full {
    display: grid;
    grid-template-columns: 1fr 1fr;
    align-items: center;
  }

  .upload-popup-wrapper.create-account .full .left {
    padding: 22px 30px;
  }
}

@media screen and (max-width: 768px) {
  .upload-popup-wrapper.create-account .full {
    display: flex;
    flex-direction: column;
    grid-template-columns: 1fr;
    align-items: center;
  }

  .upload-popup-wrapper.create-account .full .left {
    order: 2;
    padding: 20px;
  }
  .upload-popup-wrapper.create-account .full .right {
    display: none;
  }

  .upload-popup-wrapper.create-account .upload-popup-cnt {
    width: 95%;
    height: calc(100vh - 80px);
    max-height: inherit;
    top: 40px;
  }
  .close {
    position: absolute;
    top: 24px;
    right: 24px;
    width: 18px;
    height: 18px;
    background-size: 18px;
    cursor: pointer;

    svg {
      color: #ffffff;
      font-size: 30px;
    }
  }
}

@media screen and (max-width: 767px) {
  .upload-popup-wrapper.create-account .two-part {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 0;
    width:100%;
    gap: 10px;
  }

  .upload-popup-wrapper.create-account .upload-popup-cnt {
    width: 100%;
    height: 100vh;
    max-height: inherit;
    top: 0 !important;
    padding-top: 100px !important;
  }
}

@media screen and (max-width: 320px) {
  .upload-popup-wrapper.create-account .sign,
  .upload-popup-wrapper.create-account .sign a {
    font-size: 12px;
  }
}

.helper-text {
  font-size: 0.7rem;
  color: grey;
}
