.loader-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  animation: fadeIn 0.2s;
  background-color: #ffffff;

  img {
    width: 400px !important;
  }

  svg {
    animation: loading 3s linear infinite;
    font-size: 50px;
    @keyframes loading {
      0% {
        transform: rotate(0);
      }
      100% {
        transform: rotate(360deg);
      }
    }
  }
}

.gallery-modal {
  .loader-container{
    height: calc(100vh - 50px);
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
