/*--------------------------------------------------------------
# General
--------------------------------------------------------------*/
body {
  font-family: 'Be Vietnam Pro', sans-serif;
  color: #141414;
}

p {
  margin: 0;
}

a {
  color: #141414;
  text-decoration: none;
}

.nav-link {
  color: #141414;
}

a:hover {
  color: #141414;
  text-decoration: none;
}

li {
  list-style: none;
}

table {
  th {
    font-weight: 500 !important;
    line-height: 44px;

    &:first-child {
      padding-left: 20px !important;
    }
  }

  tr {
    vertical-align: middle;
    font-size: 14px !important;
  }

  td {
    font-size: 14px !important;
    line-height: 44px;

    &:first-child {
      padding-left: 20px !important;
    }

    span {
      font-size: 14px !important;

      &.badge {
        font-weight: 500 !important
      }
    }
  }
}

.button-container {
  width: auto;
  height: 50px;
  letter-spacing: 0.5px;
  padding: 0 20px;
  font-size: 15px;
  background-color: #ffffff;
  color: #141414;
  text-transform: inherit;
  border: none;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.button-container:hover {
  background-color: white;
  color: black;
  border: 1px solid black;
}

.table > :not(caption) > * > * {
  border: none !important;
}

[contenteditable]:focus {
  outline: none !important;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: 'Barlow Condensed', sans-serif;
}

/*--------------------------------------------------------------
# Disable AOS delay on mobile
--------------------------------------------------------------*/
@media screen and (max-width: 768px) {
  table {
    .hideForMobile {
      display: none;
    }
  }
}

section {
  padding: 0;
  overflow: hidden;
}

.link-as-button {
  border: none;
  background: transparent;
  color: #141414;
  margin: 0;
  padding: 0;

  &:hover {
    color: #73838b !important;
  }

  &:focus {
    color: #73838b !important;
  }

  &:active {
    color: #73838b !important;
  }
}

.modal-open {
  overflow: hidden;
  height: 100%;
}

.invalid-border {
  border: 1px solid red !important;
}

.invalid-color {
  color: red !important;
}


.no-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;

  svg {
    font-size: 100px;
    color: #cc0000;
  }

  span {
    font-size: 20px;
    margin-top: 10px;
  }
}

.editModal {
  .modal-content {
    align-items: center;

    .modal-header {
      border: none !important;

      button {
        position: absolute;
        right: 20px;
      }
    }

    .modal-body {
      max-width: 500px;

      label {
        margin-bottom: 7px;
      }
    }

    .modal-footer {
      border: none !important;
    }
  }
}


.CookieConsent {
    bottom: 20px !important;
}

.forPc {
  display: inline-block;
}

.forMobile {
  display: none;
}

@media screen and (max-width: 767px) {
  .forPc {
    display: none;
  }

  .forMobile {
    display: inline-block;
  }
}
